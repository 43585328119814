import "./styles.scss"

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const PostAside = () => {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      allWpBanner {
        nodes {
          acfBanner {
            description
            title
            image {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  `)
  const { description, title, image } = data?.allWpBanner?.nodes?.[0]?.acfBanner

  return (
    <div className="post-aside">
      <Link to="/zabiegi/" className="post-aside">
        <div className="post-aside__img">
          <img src={image?.sourceUrl} alt={image?.altText} />
        </div>
        <div className="post-aside__content">
          <p className="post-aside__title">{title}</p>
          <div
            className="post-aside__text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="post-aside__cta">Umów wizytę</div>
        </div>
      </Link>
    </div>
  )
}

export default PostAside
