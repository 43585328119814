import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import Button from "components/Button"
import PostAside from "components/PostAside"
import SliderArrow from "components/Icons/SliderArrow/slider-arrow"

const PostContent = ({ post }) => {
  const { data, next, previous } = post

  return (
    <section className="post-main">
      <div className="container">
        <div className="post-main__wrapper">
          <p className="post-main__category">{data.categories.nodes[0].name}</p>
          <h2 className="post-main__title">{data.title}</h2>
          <div className="post-main__info">
            <div className="post-main__info-item">
              <img src={require("assets/icons/calendar.svg").default} alt="" />
              <p>
                Data publikacji: <span>{data.date}</span>
              </p>
            </div>
            <div className="post-main__info-item">
              <img src={require("assets/icons/author.svg").default} alt="" />
              <p>
                Autor:{" "}
                <span>
                  {data.author.node.name || data.author.node.username}
                </span>
              </p>
            </div>
            <div className="post-main__info-item">
              <img src={require("assets/icons/clock.svg").default} alt="" />
              <p>
                Średni czas czytania: <span>{data.seo.readingTime} min</span>
              </p>
            </div>
          </div>
          <div className="post-main__image">
            <img
              src={data.acfPosts?.image?.sourceUrl}
              alt={data.acfPosts?.image?.altText}
            />
          </div>
          <div className="post-main__content-wrapper">
            <PostAside />
            <div className="post-main__content-inner">
              <div
                className="post-main__content"
                dangerouslySetInnerHTML={{
                  __html: data?.acfPosts?.postcontent,
                }}
              />
              <div className="post-main__cta">
                <p>Potrzebujesz pomocy podologa?</p>
                <Button
                  type="button"
                  to="/kontakt/"
                  className="button-dark-green"
                >
                  Skontaktuj się z nami
                </Button>
              </div>
              <div className="post-main__navigation">
                <div className="post-main__navigation-prev--wrapper">
                  {previous && (
                    <Link to={`/blog/${previous.slug}/`}>
                      <div className="post-main__navigation-prev">
                        <SliderArrow />
                        <p>Zobacz poprzedni wpis</p>
                      </div>
                    </Link>
                  )}
                </div>
                <div className="post-main__navigation-next--wrapper">
                  {next && (
                    <Link to={`/blog/${next.slug}/`}>
                      <div className="post-main__navigation-next">
                        <p>Zobacz kolejny wpis</p>
                        <SliderArrow />
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PostContent
